import AIGeneration from "./components/AIGeneration";


const AI = () => {
  return (
    <div>
      <AIGeneration />
    </div>
  );
};

export default AI;
