import {
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdFormatAlignJustify,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdTextFields,
  MdClearAll,
} from "react-icons/md";

export const tones = [
  { value: "Joyful", label: "Joyful" },
  { value: "Serious", label: "Serious" },
  { value: "Inspirational", label: "Inspirational" },
];

export const languages = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
  { value: "French", label: "French" },
];

export const ctas = [
  { value: "Subscribe", label: "Subscribe" },
  { value: "Learn More", label: "Learn More" },
  { value: "Get Started", label: "Get Started" },
];

export const templates = [
  { value: "Carousel (1:1)", label: "Carousel (1:1)" },
  { value: "Single Image (16:9)", label: "Single Image (16:9)" },
  { value: "Video (16:9)", label: "Video (16:9)" },
];

export const labels = [
  { value: "Editing", label: "Editing" },
  { value: "Final", label: "Final" },
  { value: "Draft", label: "Draft" },
];

export const fonts = [
  { id: "poppins", label: "Poppins", value: "Poppins" },
  { id: "arial", label: "Arial", value: "Arial" },
  // Add more fonts as needed
];

export const fontSizes = [
  { id: "small", label: "Small", value: "12" },
  { id: "medium", label: "Medium", value: "16" },
  { id: "large", label: "Large", value: "20" },
  // Add more sizes as needed
];

export const textAlignments = [
  { id: "left", label: "Left", value: "left", icon: MdFormatAlignLeft },
  { id: "center", label: "Center", value: "center", icon: MdFormatAlignRight },
  { id: "right", label: "Right", value: "right", icon: MdFormatAlignRight },
  {
    id: "justify",
    label: "Justify",
    value: "justify",
    icon: MdFormatAlignJustify,
  },
];

export const listStyles = [
  { id: "none", label: "None", value: "none" },
  {
    id: "bullet",
    label: "Bullet",
    value: "bullet",
    icon: MdFormatListBulleted,
  },
  {
    id: "numbered",
    label: "Numbered",
    value: "numbered",
    icon: MdFormatListNumbered,
  },
];

export const textCases = [
  { id: "uppercase", label: "abc", value: "uppercase" },
  { id: "lowercase", label: "ABC", value: "lowercase" },
  { id: "capitalize", label: "Abc", value: "capitalize" },
];
