import UserPersonaGenerator from "./components/UserPersona";


const UserPersona = () => {
  return (
    <div>
      <UserPersonaGenerator />
    </div>
  );
};

export default UserPersona;
