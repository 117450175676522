

const Instagram = () => {
    return (
      <div>
        <div className="mt-3">
          <h2>Instagram Content will goes here</h2>
        </div>
      </div>
    );
  };
  
  export default Instagram;
  