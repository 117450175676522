import React, { useState } from "react";
import InputField from "theme-components/fields/InputField";
import SelectField from "theme-components/fields/SelectField";
import TextAreaField from "theme-components/fields/TextArea";
import { ages, genders, locations } from "../variables/UserPersonaVariables";
import UserPersonaGeneratedContent from "./UserPersonaGeneratedContent";
import image2 from 'assets/img/profile/image2.png'
import Button from "theme-components/buttons/Button";

const UserPersonaGenerator = () => {
  const [age, setAge] = useState(ages[0].value);
  const [gender, setGender] = useState(genders[0].value);
  const [location, setLocation] = useState(locations[0].value);
  const [painPoints, setPainPoints] = useState(
    "Too busy to prepare healthy meal"
  );
  const [goals, setGoals] = useState("Want to consume a healthy meal everyday");
  const [generatedPersona, setGeneratedPersona] = useState(null);

  const handleGenerate = () => {
    const persona = {
      name: "Sara Kusuma",
      occupation: "Marketing Manager",
      location: "Jakarta, Indonesia",
      age: 26,
      lifestyle: "Active and busy",
      hobbies: "Light exercise, traveling, reading",
      income: "Mid to Upper Class",
      bio: `With a busy career, Sara wants to maintain her health to stay productive. She looks for food that are easy to prepare, nutritious, and support her healthy lifestyle.`,
      painPoints: [
        "Busy work schedule makes it difficult to prepare healthy meals daily.",
        "Hard to find a genuinely healthy and high-quality products.",
        "Hard to maintaining consistency in eating healthy despite a hectic lifestyle.",
      ],
      goals: [
        "Improve health and fitness through a balanced diet.",
        "Find food solutions that are easy to prepare but still nutritious.",
        "Seek products that support a healthy lifestyle and provide energy for busy days.",
      ],
      image: image2
    };
    setGeneratedPersona(persona);
  };

  return (
    <div className="grid h-screen grid-cols-10">
      <div className="col-span-3 border-r border-gray-300 bg-white p-6">
        <div className="mb-4">
          <h2 className="text-lg font-bold">User Persona</h2>
        </div>
        <div className="mb-4">
          <SelectField
            id="age"
            label="Age"
            extra="mb-2"
            variant="user-persona"
            options={ages}
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <SelectField
            id="gender"
            variant="ai-content"
            label="Gender"
            extra="mb-2"
            options={genders}
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <SelectField
            id="location"
            label="Location"
            variant="user-persona"
            extra="mb-2"
            options={locations}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <TextAreaField
            id="painPoints"
            label="Pain Points"
            variant="user-persona"
            placeholder="Your Pain Points"
            extra="mb-2"
            value={painPoints}
            onChange={(e) => setPainPoints(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <TextAreaField
            id="goals"
            label="Goals"
            extra="mb-2"
            placeholder="Your Goals"
            variant="user-persona"
            value={goals}
            onChange={(e) => setGoals(e.target.value)}
          />
        </div>
        <Button
          id="generate-button"
          label="Generate"
          variant="primary"
          onClick={handleGenerate}
          extra="mb-4"
        />
      </div>
      <div className="col-span-7 p-6 bg-gray-50 flex justify-center">
        <UserPersonaGeneratedContent generatedPersona={generatedPersona} />
      </div>
    </div>
  );
};

export default UserPersonaGenerator;
