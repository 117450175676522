import React from "react";
import Dropdown from "theme-components/dropdown";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";

import Links from "./components/Links";

const Navbar = (props: {
  brandText: string;
  username: string;
  routes: RoutesType[];
  activeRoute: string;
  logout: () => void;
}) => {
  const { logout, routes, activeRoute, username } = props;
  const [darkmode, setDarkmode] = React.useState(false);

  return (
    <nav className="sticky top-4 z-40  rounded-xl bg-white/50 p-2  backdrop-blur-xl dark:bg-[#0b14374d] dark:text-white">
      <div
        className="flex flex-row flex-wrap
      items-center justify-between py-2"
      >
        <nav className="flex space-x-4">
          <Links routes={routes} activeRoute={activeRoute} />
        </nav>

        <div className="relative mt-[3px] flex h-[32px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-lightPrimary px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-900 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[140px] xl:gap-2">
          {/* start Notification */}
          <Dropdown
            button={
              <p className="cursor-pointer">
                <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
              </p>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Notification
                  </p>
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    Mark all read
                  </p>
                </div>
              </div>
            }
            classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
          />

          <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
            )}
          </div>
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <img
                className="h-10 w-10 rounded-full"
                src={avatar}
                alt="Elon Musk"
              />
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-3 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="ml-4 mt-3">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 Hey, {username}
                    </p>{" "}
                  </div>
                </div>
                <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="ml-4 mt-3 flex flex-col">
                  <Link to={"/admin/profile"}>
                    <a
                      href=" "
                      className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                    >
                      Profile
                    </a>
                  </Link>
                  <button
                    onClick={logout}
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  >
                    Log Out
                  </button>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
