import React from "react";

import MainDashboard from "views/admin/home";
import AI from "views/admin/create/ai";
import Scripting from "views/admin/create/scripting";
import UserPersona from "views/admin//create/user-persona";
import ContentPillar from "views/admin/create/content-pillar";
import Instagram from "views/admin/trend/instagram";
import Tiktok from "views/admin/trend/tiktok";
import X from "views/admin/trend/x";
import CreatePost from "views/admin/schedule/create-post";
import Schedule from "views/admin/schedule/schedule";
import AIAnalysis from "views/admin/schedule/ai-analysis";
import Analytics from "views/admin/analytics";

const routes: RoutesType[] = [
  {
    name: "Home",
    layout: "/admin",
    path: "home",
    children: [
      {
        name: "Dashboard",
        path: "",
        component: <MainDashboard />,
        children: [],

      },
    ],
  },
  {
    name: "Trend",
    layout: "/admin",
    path: "trend",
    children: [
      {
        name: "All Trends",
        path: "",
        component: <Instagram />,
        children: [],

      },
      {
        name: "Instagram",
        path: "instagram",
        component: <Instagram />,
        children: [],

      },
      {
        name: "Tiktok",
        path: "tiktok",
        component: <Tiktok />,
        children: [],

      },
      {
        name: "X",
        path: "x",
        component: <X />,
        children: [],

      },
    ],
  },

  {
    name: "Create",
    layout: "/admin",
    path: "create",
    children: [
      {
        name: "AI",
        path: "",
        component: <AI />,
        children: [],

      },
      {
        name: "UserPersona",
        path: "userpersona",
        component: <UserPersona />,
        children: [],

      },
      {
        name: "ContentPillar",
        path: "contentpillar",
        component: <ContentPillar />,
        children: [],

      },
      {
        name: "Scripting",
        path: "scripting",
        component: <Scripting />,
        children: [],

      },
      
     
    ],
  },

  {
    name: "Schedule",
    layout: "/admin",
    path: "schedule",
    children: [
      {
        name: "Create a Post",
        path: "",
        component: <CreatePost />,
        children: [],

      },
      {
        name: "Schedule",
        path: "schedule-post",
        component: <Schedule />,
        children: [],

      },
      {
        name: "AI Analysis",
        path: "ai-analysis",
        component: <AIAnalysis />,
        children: [],

      },
    ],
  },
  {
    name: "Analytics",
    layout: "/admin",
    path: "analytics",
    children: [
      {
        name: "Analytics",
        path: "",
        component: <Analytics />,
        children: [],

      },
    ],
  },
];
export default routes;
