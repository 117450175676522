/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "theme-components/icons/DashIcon";
// chakra imports

export const SidebarLinks = (props: { routes: RoutesType[] }): JSX.Element => {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (layout: string, routeName: string) => {
    const fullPath = routeName ? `${layout}/${routeName}` : layout;
    return location.pathname === fullPath || location.pathname === `${fullPath}/`;
  };

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route, index) => (
      <Link key={index} to={route.layout + "/" + route.path}>
        <div className="relative mb-3 flex hover:cursor-pointer">
          <li
            className="my-[3px] flex cursor-pointer items-center px-8"
            key={index}
          >
            <span
              className={`${
                activeRoute(route.layout, route.path) === true
                  ? "font-bold text-brand-500 dark:text-white"
                  : "font-medium text-gray-600"
              }`}
            >
              {route.icon && route.icon}{" "}
            </span>
            <p
              className={`leading-1 ml-4 flex ${
                activeRoute(route.layout, route.path) === true
                  ? "font-bold text-navy-700 dark:text-white"
                  : "font-medium text-gray-600"
              }`}
            >
              {route.name}
            </p>
          </li>
          {activeRoute(route.layout, route.path) ? (
            <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
          ) : null}
        </div>
      </Link>
    ));
  };
  // BRAND
  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
