import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ContentLayout from "layouts/admin/ContentLayout";
import Footer from "theme-components/footer/FooterAuthDefault";
import Navbar from "theme-components/navbar";
import Sidebar from "theme-components/sidebar";

import { TopbarRoutes } from "routes";

import { useAuth } from "auth";

const DefaultRoute: RoutesType = {
  name: "Home",
  layout: "/admin/home",
  path: "home",
  children: [] as RoutesType[],
};
function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;

  const { user, logout } = useAuth();

  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState(DefaultRoute);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const location = useLocation();

  React.useEffect(() => {
    setActiveRoute(TopbarRoutes);
  }, [location.pathname]);

  const setActiveRoute = (routes: RoutesType[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i]);
      }
    }
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={`${prop.path}/*`}
          element={<ContentLayout routes={getChildRoutes(currentRoute)} />}
          key={key}
        />
      );
    });
  };

  const getChildRoutes = (route: RoutesType): RoutesType[] => {
    return route.children.map((route) => {
      route.layout = currentRoute.layout + "/" + currentRoute.path;
      return route;
    });
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar
        open={open}
        onClose={() => setOpen(false)}
        title="AI App"
        routes={getChildRoutes(currentRoute)}
        logo=""
      />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[220px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              username={user.username}
              logout={logout}
              brandText={currentRoute.name}
              routes={TopbarRoutes}
              activeRoute={currentRoute.path}
              {...rest}
            />

            <Routes>
              <Route path="/" element={<Navigate to="/admin/home" />} />
              {getRoutes(TopbarRoutes)}
            </Routes>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Admin;
