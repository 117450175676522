export const contentObjectiveOptions = [
    { value: 'Brand Awareness', label: 'Brand Awareness' },
    { value: 'Lead Generation', label: 'Lead Generation' },
    { value: 'Engagement', label: 'Engagement' },
  ];
  
  export const targetAudienceOptions = [
    { value: 'Millennials', label: 'Millennials' },
    { value: 'Gen Z', label: 'Gen Z' },
    { value: 'Baby Boomers', label: 'Baby Boomers' },
  ];
  
  export const industryOptions = [
    { value: 'Health', label: 'Health' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Finance', label: 'Finance' },
  ];

