// AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "hooks/useFetch";

interface User {
  username: string;
}

interface AuthContextType {
  user: User | null;
  authLoading: boolean,
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { data, error, loading, fetchData } = useFetch<{user: {username: string}}>();

  const login = async (email: string, password: string) => {


    await fetchData("/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    });

    if (!error && data) {
      setUser({ username: data.user?.username });
      navigate("/admin");
    } else {
      console.error(error);
    }
  };

 
  const logout = () => {

    fetchData("/auth/logout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    setUser(null);
    navigate("/auth/sign-in");
  };

  return (
    <AuthContext.Provider value={{ user, login, authLoading: loading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
