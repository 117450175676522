import React from "react";


interface ButtonProps {
  id: string;
  label: string;
  extra?: string;
  ButtonIcon?: React.ElementType,
  variant?: "primary" | "secondary" | "danger";
  state?: "default" | "disabled" | "loading";
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({
  id,
  label,
  extra = "",
  variant = "primary",
  state = "default",
  ButtonIcon,
  onClick,
}) => {
  const getButtonStyles = () => {
    switch (variant) {
      case "primary":
        return state === "disabled"
          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
          : "bg-gray-900 text-white hover:bg-gray-800";
      case "secondary":
        return state === "disabled"
          ? "bg-gray-200 text-gray-400 cursor-not-allowed"
          : "bg-white text-gray-900 border border-gray-900 hover:bg-gray-100";
      case "danger":
        return state === "disabled"
          ? "bg-red-300 text-red-500 cursor-not-allowed"
          : "bg-red-500 text-white hover:bg-red-400";
      default:
        return "";
    }
  };

  return (
    <div className={`${extra}`}>
      <button
        id={id}
        onClick={state === "disabled" ? undefined : onClick}
        className={`w-full rounded-md p-2 text-sm font-semibold ${getButtonStyles()} dark:bg-${variant === "primary" ? "gray-700" : variant === "secondary" ? "gray-800" : "red-700"} dark:text-white dark:hover:bg-${variant === "primary" ? "gray-600" : variant === "secondary" ? "gray-700" : "red-600"}`}
        disabled={state === "disabled"}
      >
      {ButtonIcon && <ButtonIcon />}
      {label}
      </button>
    </div>
  );
};

export default Button;
