

const CreatePost = () => {
    return (
      <div>
        <div className="mt-3">
          <h2>AI Schedule Content will goes here</h2>
        </div>
      </div>
    );
  };
  
  export default CreatePost;
  