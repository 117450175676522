import React, { useState } from "react";
import {
  languages,
  tones,
  ctas,
  templates,
  labels,
  fonts,
  fontSizes,
  textAlignments,
  listStyles,
  textCases,
} from "../variables/ScriptingVariables";
import GeneratedScript from "./GeneratedScript";
import Tabs from "./ScriptingTabs";
import StyleTab from "./StyleTab"; // Import the StyleTab component
import TextTab from "./TextTab"; // Import the TextTab component
import MediaTab from "./MediaTab"; // Import the MediaTab component
import Button from "theme-components/buttons/Button";

const Scripting = () => {
  const [activeTab, setActiveTab] = useState("style");
  const [tone, setTone] = useState(tones[0].value);
  const [language, setLanguage] = useState(languages[0].value);
  const [cta, setCta] = useState(ctas[0].value);
  const [template, setTemplate] = useState(templates[0].value);
  const [label, setLabel] = useState(labels[0].value);

  const [values, setValues] = useState([]);
  const [font, setFont] = useState(fonts[0].value);
  const [fontSize, setFontSize] = useState(fontSizes[0].value);
  const [textAlignment, setTextAlignment] = useState(textAlignments[0].value);
  const [listStyle, setListStyle] = useState(listStyles[0].value);
  const [textCase, setTextCase] = useState(textCases[0].value);


  const [keyword, setKeyword] = useState("");
  const [keyMessage, setKeyMessage] = useState("");
  const [generatedScript, setGeneratedScript] = useState([]);
  const [switchChecked, setSwitchChecked] = useState(false);

  const handleGenerate = () => {
    const scripts = [
      {
        title: "Healthy Life",
        items: [
          "Morning routines of health enthusiasts.",
          "Guided meditation sessions.",
          "Daily habits for a healthier life.",
        ],
      },
      {
        title: "Healthy Relationship",
        items: [
          "Healthy recipes for family meals.",
          "Tips for balancing work and a healthy lifestyle.",
          "Organizing and promoting local healthy activities.",
        ],
      },
      {
        title: "Healthy Food",
        items: [
          "Easy and quick oat-based breakfast.",
          "The nutritional benefits of oats.",
          "Local farmers and producers of oats.",
        ],
      },
    ];

    setGeneratedScript(scripts);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "style":
        return (
          <StyleTab
            keyword={keyword}
            setKeyword={setKeyword}
            keyMessage={keyMessage}
            setKeyMessage={setKeyMessage}
            tone={tone}
            setTone={setTone}
            language={language}
            setLanguage={setLanguage}
            cta={cta}
            setCta={setCta}
            template={template}
            setTemplate={setTemplate}
            label={label}
            setLabel={setLabel}
            switchChecked={switchChecked}
            setSwitchChecked={setSwitchChecked}
          />
        );
      case "text":
        return (
          <TextTab 
          values={values}
          setValues={setValues}
          font={font}
          setFont={setFont}
          fontSize={fontSize}
          setFontSize={setFontSize}
          textAlign={textAlignment}
          setTextAlign={setTextAlignment}
          listStyle={listStyle}
          setListStyle={setListStyle}
          textCase={textCase}
          setTextCase={setTextCase}

          />
        );
      case "media":
        return <MediaTab  />;
      default:
        return null;
    }
  };

  return (
    <div className="grid h-screen grid-cols-10">
      <div className="col-span-3 border-r border-gray-300 bg-white p-6">
        <div className="mb-4">
          <h2 className="text-lg font-bold">Scripting</h2>
        </div>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        {renderTabContent()}

        <Button
          id="generate-button"
          label="Generate"
          variant="primary"
          onClick={handleGenerate}
          extra="mb-4"
        />
        <Button
          id="schedule-button"
          label="Schedule"
          variant="secondary"
          onClick={handleGenerate}
          extra="mb-4"
        />
      </div>
      <div className="col-span-7 flex justify-center bg-gray-50 p-6">
        <GeneratedScript generatedScript={generatedScript} />
      </div>
    </div>
  );
};

export default Scripting;
