import React from 'react';


const AIGeneratedContent = (props: { generatedContent: string }) => {

  const { generatedContent } = props;

  return (generatedContent ? (
          <div className="">
            <h2 className="text-xl font-bold mb-4">Generated Content</h2>
            <p>{generatedContent}</p>
          </div>
        ) : (
          <p className="text-gray-500 text-center">Don’t worry, your content will appear here after you generate.</p>
        )
  );
};

export default AIGeneratedContent;
