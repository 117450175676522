import React from "react";
import Button from "./Button";

interface ButtonGroupProps {
  buttons: Array<{
    id: string;
    label?: string;
    ButtonIcon?: React.ElementType;
    extra?: string;
    variant?: "primary" | "secondary" | "danger";
    state?: "default" | "disabled" | "loading";
    onClick: () => void;
  }>;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttons }) => {
  return (
    <div className="flex space-x-2">
      {buttons.map((button) => (
        <Button
          key={button.id}
          id={button.id}
          label={button.label}
          ButtonIcon={button.ButtonIcon}
          extra={button.extra}
          variant={button.variant}
          state={button.state}
          onClick={button.onClick}
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
