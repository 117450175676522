import ContentPillarTable from "./components/ContentPillarTable";
import { tableDataColumns } from "./variables/ContentPillarTypes";

const ContentPillar = () => {
  return (
    <div>
      <ContentPillarTable tableData={tableDataColumns} />
    </div>
  );
};

export default ContentPillar;
