import React from "react";

const GeneratedScript = (props: { generatedScript: Array<any> }) => {
  const { generatedScript } = props;
  return (
    <div className="">
      {generatedScript.length > 0 ? (
        generatedScript.map((script, index) => (
          <div key={index} className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">{script.title}</h2>
            <div className="grid grid-cols-3 gap-4">
              {script.items.map((item: string, idx: number) => (
                <div
                  key={idx}
                  className="rounded-md bg-gray-100 p-4 shadow-sm hover:bg-gray-200"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">
          Don’t worry, your content will appear here after you generate.
        </p>
      )}
    </div>
  );
};

export default GeneratedScript;
