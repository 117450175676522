import React, { useState } from 'react';
import InputField from 'theme-components/fields/InputField';
import SelectField from 'theme-components/fields/SelectField';
import { contentObjectiveOptions, industryOptions, targetAudienceOptions } from '../variables/AIGenerationVariables';
import TextAreaField from 'theme-components/fields/TextArea';
import AIGeneratedContent from './AIGeneratedContent';
import Button from 'theme-components/buttons/Button';

const AIGeneration = () => {
  const [brandDescription, setBrandDescription] = useState('Happy Oats existed to help people with GERD');
  const [contentObjective, setContentObjective] = useState(contentObjectiveOptions[0].value);
  const [targetAudience, setTargetAudience] = useState(targetAudienceOptions[0].value);
  const [industry, setIndustry] = useState(industryOptions[0].value);
  const [generatedContent, setGeneratedContent] = useState('');

  const handleGenerate = () => {
    // Handle generate button click
    const generatedText = `Generated content based on: 
      Brand Description: ${brandDescription},
      Content Objective: ${contentObjective},
      Target Audience: ${targetAudience},
      Industry: ${industry}.`;
    setGeneratedContent(generatedText);
  };

  return (
    <div className="grid grid-cols-10 h-screen">
      <div className="col-span-3 p-6 border-r border-gray-300 bg-white">
        <div className="mb-4">
          <h2 className="text-lg font-bold">AI Generation</h2>
        </div>
        <div className="mb-4">
          <TextAreaField
            id="brand-description"
            label="Brand"
            extra="mb-2"
            placeholder="Tell us more about your brand"
            variant="ai-content"
            value={brandDescription}
            onChange={(e) => setBrandDescription(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <SelectField
            id="content-objective"
            label="Content Objective"
            extra="mb-2"
            options={contentObjectiveOptions}
            variant="ai-content"
            value={contentObjective}
            onChange={(e) => setContentObjective(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <SelectField
            id="target-audience"
            label="Target Audience"
            extra="mb-2"
            options={targetAudienceOptions}
            variant="ai-content"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <SelectField
            id="industry"
            label="Industry"
            extra="mb-2"
            options={industryOptions}
            variant="ai-content"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          />
        </div>
        <Button
          id="generate-button"
          label="Generate"
          variant="primary"
          onClick={handleGenerate}
          extra="mb-4"
        />
      </div>
      <div className="col-span-7 p-6 bg-gray-50 flex justify-center">
        <AIGeneratedContent generatedContent={generatedContent} />
      </div>
    </div>
  );
};

export default AIGeneration;
