import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

import { useAuth, AuthProvider } from './auth';
import { ReactElement } from "react";

interface ProtectedRouteProps {
  element: ReactElement;
}

const ProtectedRoute = ({ element }: ProtectedRouteProps): ReactElement => {
  const { user } = useAuth();

  return user ? element : <Navigate to="/auth/sign-in" replace />;
};



const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<ProtectedRoute element={<AdminLayout />} />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </AuthProvider>
    
  );
};

export default App;