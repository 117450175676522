export type RowObj = {
  name: string;
  subContentPillar: string;
  contentType: string;
  contentIdeas: string;
  hashtag: string;
};

export const tableDataColumns: RowObj[] = [
  {
    name: "Healthy Life",
    subContentPillar:
      "1. Physical Wellness\n2. Mental Wellness\n3. Daily Routines",
    contentType: "• Blog posts\n• Videos\n• Infographics\n• Live sessions",
    contentIdeas:
      "• Morning routines of health enthusiasts.\n• Guided meditation sessions.\n• Daily habits for a healthier life.",
    hashtag: "#HealthyLife\n#WellnessJourney\n#MindAndBody\n#DailyWellness",
  },
  {
    name: "Healthy Relationship",
    subContentPillar:
      "1. Family Health\n2. Social Connections\n3. Work-Life Balance",
    contentType:
      "• Blog posts\n• Videos\n• Infographics\n• User-generated content",
    contentIdeas:
      "• Healthy recipes for family meals.\n• Tips for balancing work and a healthy lifestyle.\n• Organizing and promoting local healthy...",
    hashtag:
      "#HealthyRelationships\n#FamilyHealth\n#SocialWellness\n#WorkLifeBalance",
  },
  {
    name: "Healthy Food",
    subContentPillar:
      "1. Recipes and Cooking Tips\n2. Nutritional Information\n3. Seasonal and Local Foods",
    contentType: "• Blog posts\n• Recipe videos\n• Instagram Stories and Reels",
    contentIdeas:
      "• Easy and quick oat-based breakfast recipes.\n• The nutritional benefits of oats.\n• Local farmers and producers of oats.",
    hashtag: "#HealthyFood\n#NutritionTips\n#SeasonalEating\n#LocalFoods",
  },
];
