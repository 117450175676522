import React, { Dispatch, SetStateAction } from "react";
import SelectField from "theme-components/fields/SelectField";
import Button from "theme-components/buttons/Button";
import ButtonGroup from "theme-components/buttons/ButtonGroup";

import {
  fonts,
  fontSizes,
  textAlignments,
  listStyles,
  textCases,
} from "../variables/ScriptingVariables";
import TextAreaField from "theme-components/fields/TextArea";
import { MdClose, MdDelete } from "react-icons/md";

interface TextTabProps {
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  font: string;
  setFont: (value: string) => void;
  fontSize: string;
  setFontSize: (value: string) => void;
  textAlign: string;
  setTextAlign: (value: string) => void;
  listStyle: string;
  setListStyle: (value: string) => void;
  textCase: string;
  setTextCase: (value: string) => void;
}

const TextTab: React.FC<TextTabProps> = ({
  values,
  setValues,
  font,
  setFont,
  fontSize,
  setFontSize,
  textAlign,
  setTextAlign,
  listStyle,
  setListStyle,
  textCase,
  setTextCase,
}) => {
  const handleAddField = () => {
    setValues([...values, ""]);
  };

  const handleTextChange =
    (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValues = [...values];
      newValues[index] = e.target.value;
      setValues(newValues);
    };

  const handleRemoveField = (index: number) => () => {
    const newValues = values.filter((_, i) => i !== index);
    setValues(newValues);
  };

  const textAlignButtons = textAlignments.map((alignment) => {
    return {
      id: alignment.id,
      ButtonIcon: alignment.icon,
      variant: "secondary" as "secondary",
      extra: "mr-2",
      state:
        textAlign === alignment.value
          ? "default"
          : ("disabled" as "default" | "disabled"),
      onClick: () => setTextAlign(alignment.value),
    };
  });

  const listStyleButtons = listStyles.map((style) => {
    return {
      id: style.id,
      ...{ [style.icon ? "ButtonIcon" : "label"]: style.icon || style.label },
      variant: "secondary" as "secondary",
      extra: "mr-2",
      state:
        listStyle === style.value
          ? "default"
          : ("disabled" as "default" | "disabled"),
      onClick: () => setListStyle(style.value),
    };
  });

  const textCaseButtons = textCases.map((_case) => {
    return {
      id: _case.id,
      label: _case.label,
      variant: "secondary" as "secondary",
      extra: "mr-2",
      state:
        textCase === _case.value
          ? "default"
          : ("disabled" as "default" | "disabled"),
      onClick: () => setTextCase(_case.value),
    };
  });

  return (
    <div>
      <div className="mb-4">
        <Button
          id="add-text-button"
          label="Add a text box"
          variant="secondary"
          onClick={handleAddField}
          extra="mb-4"
        />
      </div>
      {values.map((value, index) => (
        <div key={index} className="mb-4 flex items-center">
          <TextAreaField
            id={`text-${index + 1}`}
            label={`Text ${index + 1}`}
            extra="mb-2 flex-1"
            placeholder={`Add Text ${index + 1}`}
            variant="text-tab"
            value={value}
            onChange={handleTextChange(index)}
          />
          <button
            onClick={handleRemoveField(index)}
            className="mr-2 text-sm text-red-500 hover:text-red-700"
          >
            <MdClose size={20} />
          </button>
        </div>
      ))}
      <div className="mb-4">
        <SelectField
          id="fonts"
          label="Fonts"
          extra="mb-2"
          variant="text-tab"
          options={fonts}
          value={font}
          onChange={(e) => setFont(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <SelectField
          id="fontSize"
          label="Font Size"
          extra="mb-2"
          variant="text-tab"
          options={fontSizes}
          value={fontSize}
          onChange={(e) => setFontSize(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="mb-2 block text-sm font-medium text-gray-700">
          Text Align
        </label>
        <div className="flex space-x-2">
          <ButtonGroup buttons={textAlignButtons} />
        </div>
      </div>
      <div className="mb-4">
        <label className="mb-2 block text-sm font-medium text-gray-700">
          List Style
        </label>
        <div className="flex space-x-2">
          <ButtonGroup buttons={listStyleButtons} />
        </div>
      </div>
      <div className="mb-4">
        <label className="mb-2 block text-sm font-medium text-gray-700">
          Case
        </label>

        <div className="flex space-x-2">
          <ButtonGroup buttons={textCaseButtons} />
        </div>
      </div>
    </div>
  );
};

export default TextTab;
