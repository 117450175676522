import React from "react";

const UserPersonaGeneratedContent = (props: { generatedPersona: any }) => {
  const { generatedPersona } = props;

  return generatedPersona ? (
    <div className="">
      <div className="flex">
        <div className="mr-4">
          <img
            src={generatedPersona.image}
            alt="User persona Image"
            className="h-full w-80 object-cover"
          />
        </div>
        <div>
          <h2 className="text-lg font-bold">Generated Persona</h2>
          <p>
            <strong>Name:</strong> {generatedPersona.name}
          </p>
          <p>
            <strong>Occupation:</strong> {generatedPersona.occupation}
          </p>
          <p>
            <strong>Location:</strong> {generatedPersona.location}
          </p>
          <p>
            <strong>Age:</strong> {generatedPersona.age}
          </p>
          <p>
            <strong>Lifestyle:</strong> {generatedPersona.lifestyle}
          </p>
          <p>
            <strong>Hobbies:</strong> {generatedPersona.hobbies}
          </p>
          <p>
            <strong>Income:</strong> {generatedPersona.income}
          </p>
          <p>
            <strong>Bio:</strong> {generatedPersona.bio}
          </p>
        </div>
      </div>
      <p>
        <strong>Pain Points:</strong>
      </p>
      <ul>
        {generatedPersona.painPoints.map((point: string, index: number) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
      <p>
        <strong>Goals:</strong>
      </p>
      <ul>
        {generatedPersona.goals.map((goal: string, index: number) => (
          <li key={index}>{goal}</li>
        ))}
      </ul>
    </div>
  ) : (
    <p className="text-center text-gray-500">
      Don’t worry, your content will appear here after you generate.
    </p>
  );
};

export default UserPersonaGeneratedContent;
