import Scripting from "./components/Scripting";

const ScriptingIndex = () => {
  return (
    <div>
      <Scripting />
    </div>
  );
};

export default ScriptingIndex;
