import React from "react";

const Tabs = (props: { activeTab: string; setActiveTab: Function }) => {
  const { activeTab, setActiveTab } = props;
  return (
    <div className="mb-4 flex space-x-4">
      <button
        className={`px-3 py-2 text-sm font-medium ${
          activeTab === "style" ? "border-b-2 border-gray-900" : ""
        }`}
        onClick={() => setActiveTab("style")}
      >
        Style
      </button>
      <button
        className={`px-3 py-2 text-sm font-medium ${
          activeTab === "text" ? "border-b-2 border-gray-900" : ""
        }`}
        onClick={() => setActiveTab("text")}
      >
        Text
      </button>
      <button
        className={`px-3 py-2 text-sm font-medium ${
          activeTab === "media" ? "border-b-2 border-gray-900" : ""
        }`}
        onClick={() => setActiveTab("media")}
      >
        Media
      </button>
    </div>
  );
};

export default Tabs;
