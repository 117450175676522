import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { TopbarRoutes } from "routes"; // Ensure this import is correct

export default function ContentLayout(props: { routes: RoutesType[] }) {
  const { routes } = props;

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={prop.component}
          key={key}
        />
      );
    });
  };

  return (
    <div className="mx-auto mb-auto mt-8 h-full min-h-[84vh] rounded-xl bg-white p-4 backdrop-blur-xl dark:!bg-navy-800 dark:text-white">
      <Routes>{getRoutes(routes)}</Routes>
    </div>
  );
}
