import React, { useState } from "react";

// Define the component
const MediaTab: React.FC = () => {
  // State to hold the images
  const [images, setImages] = useState<string[]>([
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
  ]);

  // Handle image upload
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <div className="mb-4">
          <input
            type="file"
            accept="image/*"
            multiple
            className="hidden"
            id="upload-input"
            onChange={handleImageUpload}
          />
          <label
            htmlFor="upload-input"
            className="cursor-pointer rounded bg-gray-200 px-4 py-2"
          >
            Upload Image
          </label>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="flex h-24 items-center justify-center rounded bg-gray-300"
            >
              {image ? (
                <img
                  src={image}
                  alt={`Image ${index}`}
                  className="h-full w-full rounded object-cover"
                />
              ) : (
                <span>No Image</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediaTab;
