// useFetch.ts
import { useState, useEffect, useCallback } from "react";

const BASEURL = "https://influencer-api-tvrp.onrender.com/v1";

interface FetchOptions {
  method?: string;
  headers?: HeadersInit;
  body?: BodyInit | null;
}

export const useFetch = <T,>() => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async (url: string, options?: FetchOptions) => {
    setLoading(true);
    setError(null);


    try {
      const response = await fetch(`${BASEURL}${url}`, options);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setData(data);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { data, error, loading, fetchData };
};
