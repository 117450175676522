import React from "react";
import InputField from "theme-components/fields/InputField";
import TextAreaField from "theme-components/fields/TextArea";
import SelectField from "theme-components/fields/SelectField";

import {
  languages,
  tones,
  ctas,
  templates,
  labels,
} from "../variables/ScriptingVariables";

interface StyleTabProps {
  keyword: string;
  setKeyword: (value: string) => void;
  keyMessage: string;
  setKeyMessage: (value: string) => void;
  tone: string;
  setTone: (value: string) => void;
  language: string;
  setLanguage: (value: string) => void;
  cta: string;
  setCta: (value: string) => void;
  template: string;
  setTemplate: (value: string) => void;
  label: string;
  setLabel: (value: string) => void;
  switchChecked: boolean;
  setSwitchChecked: (value: boolean) => void;
}

const StyleTab: React.FC<StyleTabProps> = ({
  keyword,
  setKeyword,
  keyMessage,
  setKeyMessage,
  tone,
  setTone,
  language,
  setLanguage,
  cta,
  setCta,
  template,
  setTemplate,
  label,
  setLabel,
  switchChecked,
  setSwitchChecked,
}) => {
  return (
    <div>
      <div className="mb-4">
        <InputField
          id="keywords"
          label="Keywords"
          variant="style-tab"
          placeholder="Enter Keywords"
          extra="mb-2"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <TextAreaField
          id="keyMessage"
          label="Key Message"
          variant="style-tab"
          placeholder="Enter Your Key Message"
          extra="mb-2"
          value={keyMessage}
          onChange={(e) => setKeyMessage(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <SelectField
          id="tone"
          label="Tone/Mood"
          extra="mb-2"
          variant="style-tab"
          options={tones}
          value={tone}
          onChange={(e) => setTone(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <SelectField
          id="language"
          variant="style-tab"
          label="Language"
          extra="mb-2"
          options={languages}
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <SelectField
          id="cta"
          variant="style-tab"
          label="CTA"
          extra="mb-2"
          options={ctas}
          value={cta}
          onChange={(e) => setCta(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <SelectField
          id="template"
          variant="style-tab"
          label="Template"
          extra="mb-2"
          options={templates}
          value={template}
          onChange={(e) => setTemplate(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <SelectField
          id="label"
          variant="style-tab"
          label="Label"
          extra="mb-2"
          options={labels}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </div>
    </div>
  );
};

export default StyleTab;
