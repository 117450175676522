

const Dashboard = () => {
  return (
    <div>
      <div className="mt-3">
        <h2>Dashbord Content will goes here</h2>
      </div>
    </div>
  );
};

export default Dashboard;
