/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

export const TopbarLinks = (props: { routes: RoutesType[], activeRoute: string}): JSX.Element => {
  // Chakra color mode

  const { routes, activeRoute } = props;

  // verifies if routeName is the one active (in browser input)
  const _activeRoute = (routeName: string) => {
    return activeRoute === routeName;
  };

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route, index) => (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className="hover:cursor-pointer">
              <li
                className="flex cursor-pointer items-center"
                key={index}
              >
                <span
                  className={`${
                    _activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon && route.icon}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    _activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
             
            </div>
          </Link>
        ));
  };
  // BRAND
  return <>{createLinks(routes)}</>;
};

export default TopbarLinks;
