import React, { useState } from "react";
import InputField from "theme-components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "theme-components/checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useFetch } from "hooks/useFetch"; // Adjust the path as necessary

export default function SignUp() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  const { data, error, loading, fetchData } = useFetch<any>();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (!agreeToTerms) {
      alert("You must agree to the terms and conditions.");
      return;
    }

    const userDetails = { username: name, email, password };

    await fetchData("/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDetails),
    });

    console.log(error, data)

    if (!error) {
      navigate("/auth/sign-in");
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your details to create your account!
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign Up with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Name*"
          placeholder="John Doe"
          id="name"
          type="text"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox
              checked={agreeToTerms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreeToTerms(e.target.checked)}
            />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              I agree to the terms and conditions
            </p>
          </div>
        </div>
        <button
          onClick={handleSignUp}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {loading ? "Signing Up..." : "Sign Up"}
        </button>
        {error && <p className="mt-4 text-sm text-red-500">{error}</p>}
        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Already have an account?
          </span>
          <Link to="/auth/sign-in">
            <a className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
              Sign In
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
